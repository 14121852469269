import "./App.css";
import React, { useEffect, useState } from "react";
import { authorize, getPhoneNumber, getSetting, openPermissionSetting   } from "zmp-sdk/apis";

function App() {
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    console.log("code", code);
    if (code) {
      fetchAccessToken(code);
    }
  }, []);

  const fetchAccessToken = async (code) => {
    const appId = "4500098784345973599";
    try {
      console.log("code2", appId);

      const response = await fetch(
        "https://oauth.zaloapp.com/v4/access_token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            secret_key: "rmWQLZAV5EPWX3SnJY5D",
          },
          body: new URLSearchParams({
            code: code,
            app_id: appId,
            grant_type: "authorization_code",
            code_verifier: "73l8gRjwLftklgfdXT+MdiMEjJwGPVMsyVxe16iYpk8=",
          }),
        }
      );

      const result = await response.json();
      const accessToken = result.access_token;
      console.log("response", accessToken);
      setToken(accessToken);
      fetchUserInfo(accessToken);
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const fetchUserInfo = async (accessToken) => {
    try {
      const response = await fetch(
        "https://graph.zalo.me/v2.0/me?fields=id,name,picture",
        {
          headers: {
            access_token: accessToken,
          },
        }
      );
      console.log("response 222", await response.json());
      const userInfo = await response.json();
      setData(userInfo);
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const handleLogin = () => {
    const appId = "4500098784345973599";
    const redirectUri = "http://localhost:3000/";
    const oauthUrl = `https://oauth.zaloapp.com/v4/permission?app_id=${appId}&redirect_uri=${redirectUri}&state=12345`;

    window.location.href = oauthUrl;
  };

  const handleAcceptPermission = async () => {
    openPermissionSetting({
      success: () => {
        // xử lý khi gọi api thành công
        console.log("success", )
      },
      fail: (error) => {
        // xử lý khi gọi api thất bại
        console.log("error", error);
      }
    });
    // try {
    //   const data = await authorize({
    //     scopes: ["scope.userLocation", "scope.userPhonenumber"],
    //   });
    //   console.log("111", data);

    //   // getPhoneNumber({
    //   //   success: async (data) => {
    //   //     let { token } = data;

    //   //     console.log("=toek", token);
    //   //   },
    //   //   fail: (error) => {
    //   //     // Xử lý khi gọi api thất bại
    //   //     console.log("sss", error);
    //   //   },
    //   // });
    // } catch (error) {
    //   // xử lý khi gọi api thất bại
    //   console.log("err", error);
    // }
    // getSetting({
    //   success: (data) => {
    //     console.log("111", data);

    //     // xử lý khi gọi api thành công. Vd:
    //     // data.authSetting = {
    //     //   "scope.userInfo": true,
    //     //   "scope.userLocation": true
    //     // }
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   }
    // })
  };

  const getSettings = () => {
    getSetting({
      success: (data) => {
        console.log("111", data);

        // xử lý khi gọi api thành công. Vd:
        // data.authSetting = {
        //   "scope.userInfo": true,
        //   "scope.userLocation": true
        // }
      },
      fail: (error) => {
        // xử lý khi gọi api thất bại
        console.log(error);
      }
    })
  }

  return (
    <div className="App">
      <div>
        <p>test zalo</p>
        <button onClick={handleLogin}>Đăng nhập bằng Zalo</button>
      </div>
      {data && (
        <div>
          <h3>Thông tin người dùng:</h3>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
      <button onClick={handleAcceptPermission}>caaps quuyen</button>
      <button onClick={getSettings}>get Setting</button>
      
    </div>
  );
}

export default App;
